import { message as messageAntd } from 'antd';
import classNames from 'classnames';
import Icon from '@/components/common/Icon';

import styles from './index.module.scss';
export default function Message() {
  return <></>;
}
type Props = {
  text: React.ReactNode | string;
  type?: 'warning' | 'success' | 'info';
  /**
   * 默认无关闭按钮 3s自动消失
   */
  closeIcon?: boolean;
  /**
   * 自定义提示图标 iconfont的类名
   */
  iconType?: string;
  iconClassName?: string;
  /**
   * 自定义样式
   */
  style?: objectType;
};
const showMessage = (props: Props) => {
  const { text, closeIcon, iconType, style, iconClassName, type } = props;
  const key = new Date().getTime();
  const getIconType = () => {
    if (!!iconType) return iconType;
    switch (type) {
      case 'success':
        return 'icon-color-check';
      case 'warning':
        return 'icon-attention-s-color';
      default:
        return '';
    }
  };
  // 全局默认置顶
  messageAntd.config({
    top: 0
  });
  messageAntd.success({
    duration: closeIcon ? 0 : 3,
    content: (
      <span className={styles.messageContent}>
        <span className={styles.messageText}>{text}</span>
        {closeIcon && (
          <Icon onClick={() => Message.destroy(key)} className={styles.closeMessageIcon} type="icon-close-s" />
        )}
      </span>
    ),
    className: styles.message,
    key,
    style: style,
    icon: (
      <Icon
        className={classNames(iconClassName, {
          [styles.successIcon]: type === 'success',
          [styles.warningIcon]: type === 'warning'
        })}
        type={getIconType()}
      />
    )
  });
};
Message.warning = arg =>
  showMessage({
    type: 'warning',
    ...arg
  });
Message.success = arg =>
  showMessage({
    type: 'success',
    ...arg
  });
Message.info = arg =>
  showMessage({
    type: 'info',
    ...arg
  });
Message.destroy = messageAntd.destroy;
