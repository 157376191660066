import loadAndInitializeGoogleSignIn from '@/utils/googleV2';
import { useEffect, useRef } from 'react';

type IProps = {
  className?: string;
  responseCallback: (param, token) => void;
  options?: any;
};

const GoogleLogin = (props: IProps) => {
  const { responseCallback, options } = props;

  const googleRef = useRef(null);

  useEffect(() => {
    if (!googleRef.current) return;
    const element = googleRef.current;
    loadAndInitializeGoogleSignIn(responseCallback, options, element).catch(error => {
      console.error('Error initializing Google Sign-In:', error);
    });
    return () => {
      googleRef.current = null;
    };
  }, [googleRef.current, responseCallback, options]);

  return <div className={props.className} ref={googleRef}></div>;
};

export default GoogleLogin;
