import { parseJwt } from '@/utils/jwt-code';

// 通用的脚本加载函数，可以加载任何外部脚本
export const loadExternalScript = (src, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      return resolve(true);
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error(`Script loading failed: ${src}`));

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  });
};

// 初始化Google登录的函数
const initializeGoogleSignIn = (google, clientId, callback, options, buttonElement) => {
  if (!google) {
    throw new Error('Google library is not available.');
  }

  google.accounts.id.initialize({
    client_id: clientId,
    callback: response => {
      const info = parseJwt(response.credential);
      callback(info, response.credential);
    }
  });

  google.accounts.id.renderButton(buttonElement, {
    theme: 'outline',
    size: 'large',
    ...options
  });
};

// 加载和初始化Google登录
export const loadAndInitializeGoogleSignIn = async (responseCallback, options, buttonElement) => {
  try {
    const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
    await loadExternalScript('https://accounts.google.com/gsi/client', 'google-jssdk-v2');
    initializeGoogleSignIn(window.google, clientId, responseCallback, options, buttonElement);
  } catch (error) {
    console.error('Error loading or initializing Google Sign-In:', error);
  }
};

export default loadAndInitializeGoogleSignIn;
