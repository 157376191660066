import getConfig from 'next/config';

export function loadFacebookSdk() {
  return new Promise<boolean>((resolve, reject) => {
    const id = 'facebook-jssdk',
      container = document.getElementsByTagName('script')[0];
    if (document.getElementById(id)) {
      return resolve(true);
    }
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.NEXT_PUBLIC_WEEE_FACEBOOK_APPID,
        xfbml: true,
        version: 'v8.0'
      });
    };
    const el = document.createElement('script');
    el.id = id;
    el.async = true;
    el.src = '//connect.facebook.net/en_US/sdk.js';
    container.parentNode.insertBefore(el, container);
    el.addEventListener('load', () => {
      resolve(true);
    });
    el.addEventListener('error', e => reject(false));
  });
}

export default function getFacebookShareUrl(url, redirect_url?) {
  const encodeUrl = encodeURIComponent(url);
  let encodeRedirectUrl = encodeUrl;
  if (redirect_url) {
    encodeRedirectUrl = encodeURIComponent(redirect_url);
  }

  return (
    'https://www.facebook.com/dialog/share?app_id=' +
    process.env.NEXT_PUBLIC_WEEE_FACEBOOK_APPID +
    '&display=popup&href=' +
    encodeUrl +
    '&redirect_uri=' +
    encodeRedirectUrl
  );
}

interface fbShareProps {
  url: string;
  hashtag?: string;
  redirectUri?: string;
  quote?: string;
}

export const encodeParams = (obj: { [key: string]: string }) => {
  return Object.keys(obj)
    .filter(k => typeof obj[k] !== 'undefined' && obj[k] !== '')
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');
};

export function getFbButtonUrl(options: fbShareProps) {
  const { url, hashtag, redirectUri = url, quote } = options;

  if (!url) {
    throw new Error('url is not defined');
  }

  const params = encodeParams({
    app_id: process.env.NEXT_PUBLIC_WEEE_FACEBOOK_APPID,
    display: 'popup',
    redirect_uri: redirectUri,
    href: url,
    hashtag: hashtag,
    quote: quote
  });

  return `https://www.facebook.com/dialog/share?${params}`;
}

export function fbShare(options: fbShareProps) {
  return window.open(
    getFbButtonUrl(options),
    '_self',
    'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0'
  );
}
