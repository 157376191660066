export function loadGoogloRecaptcha(): Promise<string> {
  return new Promise((resolve, reject) => {
    let el,
      id = 'recaptcha-sdk',
      container = document.body;
    if (document.getElementById(id)) {
      getResponseToken()
        .then(token => resolve(token))
        .catch(err => reject(err));
      return;
    }
    el = document.createElement('script');
    el.id = id;
    el.async = true;
    el.src = `https://www.google.com/recaptcha/api.js?render=${
      process.env.GOOGLE_PUBLIC_SECRET || process.env.NEXT_PUBLIC_GOOGLE_PUBLIC_SECRET
    }`;
    container.parentNode.insertBefore(el, container);
    el.addEventListener('load', () => {
      getResponseToken()
        .then(token => resolve(token))
        .catch(err => reject(err));
    });
    el.addEventListener('error', reject);
  });
}

function getResponseToken(): Promise<string> {
  return new Promise((resole, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${process.env.GOOGLE_PUBLIC_SECRET || process.env.NEXT_PUBLIC_GOOGLE_PUBLIC_SECRET}`, {
            action: 'submit'
          })
          .then(token => {
            resole(token);
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      reject('no grecaptcha');
    }
  });
}
