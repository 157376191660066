import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type IProps = {
  className?: string;
  responseCallback?: (token: string) => void;
  id: string;
};

const GoogleRecaptchaV2 = forwardRef((props: IProps, ref) => {
  const { id, className, responseCallback } = props;

  const [show, setShow] = useState(false);

  const getCaptcha = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha.render(id, {
            sitekey: process.env.RECAPTCHA_V2_KEY || process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY,
            callback: (token: string) => {
              responseCallback && responseCallback(token);
            }
          });
          resolve('grecaptcha ready');
        });
      } else {
        reject('no grecaptcha');
      }
    });
  };

  const loadRecaptchaV2 = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      let el,
        _id = 'recaptcha-v2',
        container = document.body;
      if (document.getElementById(_id)) {
        getCaptcha()
          .then(info => resolve(info))
          .catch(err => reject(err));
        return;
      }
      el = document.createElement('script');
      el.id = _id;
      el.async = true;
      el.defer = true;
      el.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
      container.parentNode.insertBefore(el, container);
      el.addEventListener('load', () => {
        getCaptcha()
          .then(info => resolve(info))
          .catch(err => reject(err));
        return;
      });
      el.addEventListener('error', reject);
    });
  };

  useImperativeHandle(ref, () => {
    return {
      visible: show,
      show: () => {
        setShow(true);
      },
      hide: () => {
        const id = document.getElementById('recaptcha-v2');
        if (window.grecaptcha && id) {
          window.grecaptcha?.reset();
          id.parentNode.removeChild(id);
        }
        setShow(false);
      },
      reset: window.grecaptcha?.reset,
      getToken: window.grecaptcha?.getResponse
    };
  });

  useEffect(() => {
    if (show) {
      loadRecaptchaV2();
    }
    return () => {
      const id = document.getElementById('recaptcha-v2');
      if (id) {
        id.parentNode.removeChild(id);
      }
    };
  }, [show]);

  if (!show) return null;

  return (
    <Fragment>
      <form action="?" method="POST" className={twMerge('w-full flex justify-center my-[13px]', className)}>
        <div className="mx-auto" id={id}></div>
      </form>
    </Fragment>
  );
});

export default GoogleRecaptchaV2;
