import { getCookie } from '@/utils/tool';
import Constants from '@/constants/index';

/**
 * 获取邀请好友的参数
 */
export const getInviterParams = () => {
  return [Constants.REFERRER_ID, Constants.REFERRER_SOURCE].reduce((pre, cur) => {
    const value = getCookie(cur);
    if (value) {
      pre[cur] = value;
    }
    return pre;
  }, {});
};
