import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import LoadingGif from '@/assets/images/spinner.gif';
import Icon from '@/components/common/Icon';
import Img from '@/components/common/Img';

import { twMerge } from 'tailwind-merge';
import styles from './index.module.scss';

export interface TextFiledInputProps {
  className?: string;
  focusStyle?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  max?: string;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onPressEnter?: any;
  customBtn?: React.ReactNode | React.ReactNode[];
  autoComplete?: string;
  maxLength?: number;
  error?: string;
  holdLabel?: boolean;
  suffix?: any;
  prefix?: any;
  focusSelect?: boolean;
  defaultFocus?: boolean;
  loading?: boolean;
  onSuccess?: () => void;
}

const defaultIcon = <Icon className={styles.btnIcon} type="direction-right" />;
const defaultLoading = <Img src={LoadingGif} className="w-5 h-5" />;

const TextFiledInput: FC<TextFiledInputProps> = props => {
  const { holdLabel = false, loading = false, customBtn = defaultIcon, autoFocus = false } = props;
  const [isFocus, setIsFocus] = useState(autoFocus);
  const [val, setVal] = useState('');
  const inputRef = useRef(null);

  const handleFocus = e => {
    setIsFocus(true);
    props?.focusSelect && !!e.target.value && inputRef.current.select();
  };

  const handleBlur = e => {
    props?.onBlur?.(e.target.value);
    setIsFocus(false);
  };

  const handleKeyDown = e => {
    e.keyCode === 13 && props?.onPressEnter?.();
  };

  const handleChange = e => {
    props?.onChange(e.target.value);
    setVal(e.target.value);
  };

  const handleSubmit = () => {
    if (!val && !props?.focusSelect) inputRef?.current.focus();
    props?.onSuccess();
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div className={styles.inputItem}>
      <div
        className={twMerge(
          classNames(
            styles.inputWrapper,
            {
              [styles.defaultFocus]: props?.defaultFocus,
              [styles.inputFocus]: isFocus || !!props?.value,
              [styles.holdLabel]: holdLabel,
              [styles.inputError]: !!props?.error
            },
            props?.className,
            isFocus && props?.focusStyle
          )
        )}
        data-role="text-filed-input">
        {!!props.prefix && (
          <div className={styles.prefix} data-role="input-text-prefix">
            {props.prefix}
          </div>
        )}
        <div className={styles.inputContent}>
          {holdLabel && <div className={styles.inputLabel}>{props?.placeholder}</div>}
          <input
            ref={inputRef}
            aria-label={props?.placeholder}
            value={props?.value || ''}
            className={styles.inputFiled}
            placeholder={!holdLabel ? props?.placeholder : undefined}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            type={props?.type}
            max={props?.max}
            maxLength={props.maxLength}
            autoComplete={props?.autoComplete || 'off'}
          />
          {!!props?.suffix && <div className={styles.suffix}>{props?.suffix}</div>}
          {!!customBtn && (
            <button
              data-role="text-filed-button"
              aria-label="more information"
              onClick={handleSubmit}
              className={classNames(styles.inputBtn)}>
              {loading ? defaultLoading : customBtn}
            </button>
          )}
        </div>
      </div>

      {props?.error && (
        <div className={styles.errInfo} data-role="text-filed-error">
          {props?.error}
        </div>
      )}
    </div>
  );
};

export default TextFiledInput;
